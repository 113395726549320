<div class="page-content">
    <action-button-group [actions]="actions" (onAction)="actionTriggered($event)"></action-button-group>

    <div class="section-filter">
        <div data-layout="row" data-layout-gap="1rem">
            <div data-flex="25">
                <mat-form-field>
                    <input matInput [(ngModel)]="searchOptions.query" placeholder="Filter by name"
                            (ngModelChange)="debounceDataSource(searchOptions)" />
                </mat-form-field>
            </div>
            <div data-flex="25">
                <mat-form-field>
                    <mat-select [(ngModel)]="searchOptions.role" placeholder="Filter by role"
                            (ngModelChange)="debounceDataSource(searchOptions)" (click)="setFilterableRoles()">
                        <mat-option value="all">
                            All Roles
                        </mat-option>
                        <mat-option *ngFor="let role of filterableRoles | sortAlphabeticallyPipe"
                                [value]="role" [hidden]="role === searchOptions.role">
                            {{role | capitalizePipe}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div data-flex="25">
                <mat-form-field>
                    <team-selector [(ngModel)]="team" [realms]="realms">
                    </team-selector>
                </mat-form-field>
            </div>
            <div data-flex="25" class="inactive-toggle">
                <mat-slide-toggle [(ngModel)]="searchOptions.seeInactive" labelPosition="before"
                        (ngModelChange)="debounceDataSource(searchOptions)">
                    Show Inactive Users:
                </mat-slide-toggle>
            </div>
        </div>
        <div data-layout="row">
            <div data-flex="100" class="text-right">
                <button mat-raised-button (click)="resetFilters()" class="clear-button main-action-button">
                    Clear Filters
                </button>
            </div>
        </div>
    </div>
    <div class="section-list-actions" *ngIf="bulkActions.length > 0">
        <div class="list-actions-container">
            <button mat-raised-button [matMenuTriggerFor]="bulkActionsMenu" class="list-action-button"
                    [disabled]="!selectedUsers?.length">
                Actions
            </button>
            <mat-menu #bulkActionsMenu="matMenu">
                <button mat-menu-item *ngFor="let action of bulkActions" (click)="triggerBulkAction(action)" class="list-action">
                    {{ action | titlecase }}
                </button>
            </mat-menu>

            <span>
                <label class="list-count-label">
                    Showing {{dataSource.total || 0}} user<span *ngIf="dataSource.total !== 1">s</span>
                </label>
            </span>
        </div>
    </div>
    <section class="page-content-section with-filter">
        <mat-table #table [dataSource]="dataSource" class="user-list">
            <ng-container cdkColumnDef="checkbox">
                <mat-header-cell *cdkHeaderCellDef>
                    <mat-checkbox [(ngModel)]="selectionSet['master']" [disabled]="!canEdit"
                            (change)="toggleAllItems(selectionSet['master'], table)"
                            [indeterminate]="selectedUsers?.length && selectedUsers?.length < dataSource.total">
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    <mat-checkbox [(ngModel)]="selectionSet[row.id]" [disabled]="!canEdit"
                            (change)="toggleSingleItem(selectionSet[row.id], row)" (click)="preventNavigation()">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <!-- ----- -->

            <ng-container cdkColumnDef="avatar">
                <mat-header-cell *cdkHeaderCellDef>
                    <!-- Empty -->
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    <img src="../../../assets/images/user-generic-icon.svg" />
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="name">
                <mat-header-cell *cdkHeaderCellDef>
                    Name
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    {{ row.name }}
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="email">
                <mat-header-cell *cdkHeaderCellDef>
                    Email
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    {{ row.email }}
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="history">
                <mat-header-cell *cdkHeaderCellDef>
                    Last Sign-in
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                     {{ row.lastSignIn | commonDate : dateDisplayFormat }}
                </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="roles">
                <mat-header-cell *cdkHeaderCellDef>
                    Roles
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row">
                    <span *ngFor="let role of row.roles" class="single-user-role">{{ role | capitalizePipe }}</span>
                </mat-cell>
            </ng-container>

            <!-- <ng-container cdkColumnDef="actions">
                <mat-header-cell *cdkHeaderCellDef>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="text-right">
                    <button mat-icon-button class="pull-right" (click)="editUser(row)" matTooltipPosition="below" matTooltip="Edit User"
                            *ngIf="currentUser.hasPermission('user-update') || (currentUser.hasPermission('user-self-update') && currentUser.id === row.id)">
                        <mat-icon>
                            mode_edit
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container> -->

            <!-- ----- -->

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns;" (click)="editUser(row)" class="clickable"></mat-row>
        </mat-table>

        <!-- <mat-paginator #paginator [hidden]="!showPager"
                [length]="dataSource.total"
                [pageIndex]="0"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                (page)="onPageChange($event)">
        </mat-paginator> -->

        <div data-layout="row" data-layout-align="center center" class="empty-state-container"
                *ngIf="dataSource && dataSource.total === 0 && !searchOptions.query && !searchOptions.role">
            <div data-flex="100">
                <img src="../../../assets/images/empty-state-review.svg" />

                <p class="empty-state-warning">
                    There are no <span *ngIf="!searchOptions.seeInactive">active</span> users on this team.
                </p>

                <button mat-raised-button (click)="createUser()">
                    Start adding users
                </button>
            </div>
        </div>

        <div data-layout="row" data-layout-align="center center" class="empty-state-container"
                *ngIf="dataSource && dataSource.total === 0 && (searchOptions.query || searchOptions.role)">
            <div data-flex="100">
                <p class="empty-state-warning">
                    There are no users that match your criteria
                </p>
            </div>
        </div>
    </section>
</div>
