import {
    Component, OnInit, ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import {
    User, Team
} from "@shopliftr/common-js/shared";

import { AuthenticationService } from "@shopliftr/common-ng";

@Component({
    selector: "dashboard-container",
    templateUrl: "./dashboard.component.html",
    encapsulation: ViewEncapsulation.None
})

export class DashboardComponent implements OnInit {

    currentUser: User;

    currentDashboard: string;

    dashboardType: string;

    dashboardCount: number;

    get team(): Team {

        return this._team;
    }

    set team(team: Team) {

        this._team = team;
        if (team) {
            localStorage.setItem("taskManagmentTeamId", team.id);
        }
    }

    private _team: Team;

    constructor(
        private readonly _authService: AuthenticationService,
        private readonly _router: Router
    ) {}


    ngOnInit(): void {

        this.dashboardCount = 0;
        this.currentUser = this._authService.authenticatedUser.getValue();

        const storedTeamId = localStorage.getItem("taskManagmentTeamId");
        if (storedTeamId) {
            this._team = Team.deserialize({
                id: storedTeamId
            });
        }
        else {
            this._team = Team.deserialize({
                id: this.currentUser.teamId,
                name: this.currentUser.teamName
            });
        }
        for (const permission of this.currentUser.permissions) {
            if (permission.includes("dashboard")) {
                this.dashboardCount = this.dashboardCount + 1;
            }
        }
        if (this.currentUser.hasPermission("dashboard-team-flyers")) {
            this.currentDashboard = "team";
        }
        else if (this.currentUser.hasPermission("dashboard-user-flyers")) {
            this.currentDashboard = "user";
        }


    }


    dashboardSelect(type: string): void {

        switch (type) {
        case "team":
            this.currentDashboard = type;
            break;
        case "user":
            this.currentDashboard = type;
            break;
        default:
            this.currentDashboard = "user";
            break;
        }
    }


    onTaskNavigate(filter: string): void {

        void this._router.navigate(["admin/tasks/list"], {
            queryParams: {
                filter: filter
            }
        });
    }
}
