<header class="page-title">
    <h2>
        <span class="nav-title">Api Keys</span>

        <mat-icon *ngIf="apiKey">
            chevron_right
        </mat-icon>

        <span *ngIf="apiKey && !apiKey.key">New Api Key</span>

        <span *ngIf="apiKey && apiKey.key">Edit Api Key</span>
    </h2>
</header>
<section>
    <div class="api-key-wrapper-component">
        <api-key-list *ngIf="!apiKey" [currentUser]="currentUser" (selectApiKey)="setApiKey($event)"></api-key-list>
        <api-key-form *ngIf="apiKey" [selectedApiKey]="apiKey" (complete)="clearApiKey()"> </api-key-form>
    </div>
</section>
