<div class="page-content">
    <section class="page-content-section">
        <div class="user-details-wrapper">
            <form #userUpdateForm="ngForm" (submit)="submitUser()">
                <!-- not proper permission checking, but hack for sysadmin to get user id -->
                <div data-layout="row" *ngIf="currentUser.hasRole('sysadmin') && editableUser.id">
                    <div data-flex="100">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="User Id" [ngModel]="editableUser.id" name="userId" disabled />
                        </mat-form-field>
                    </div>
                </div>

                <!-- TODO: user-set-role-admin???? -->
                <div data-layout="row" [hidden]="(editableUser === currentUser) || !currentUser.hasPermission('user-set-role-admin')">
                    <div data-flex="15">
                        <button mat-raised-button type="button" (click)="createTeam($event)">
                            Create New Team
                        </button>
                    </div>

                    <div data-flex="85">
                        <mat-form-field>
                            <team-selector #teamSelector  [required]="true" name="team" [(ngModel)]="selectedTeam">
                            </team-selector>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row" data-layout-gap="1rem">
                    <div data-flex="100">
                        <mat-form-field>
                            <input matInput #email="ngModel" [(ngModel)]="editableUser.email" type="text"
                                    placeholder="E-Mail" name="email" required emailValidator/>

                            <mat-error *ngIf="email.touched && !email.valid">
                                Please provide a valid email
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row" data-layout-gap="1rem">
                    <div data-flex="50">
                        <mat-form-field>
                            <input matInput #firstName="ngModel" [(ngModel)]="editableUser.firstName" type="text"
                                    placeholder="First Name" name="firstName" required/>
                        </mat-form-field>
                    </div>
                    <div data-flex="50">
                        <mat-form-field>
                            <input matInput #lastName="ngModel" [(ngModel)]="editableUser.lastName" type="text"
                                    placeholder="Last Name" name="lastName" required/>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row" *ngIf="roles?.length">
                    <div data-flex="100">
                        <mat-form-field>
                            <multi-selector [(ngModel)]="editableUser.roles"
                                    [options]="roles"
                                    name="roleSelector"
                                    placeholder="Roles"
                                    required="true"
                                >
                            </multi-selector>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row" *ngIf="editableUser !== currentUser" [@fadeAnimation]>
                    <div data-flex="100">
                        <mat-form-field *ngIf="teams">
                            <multi-selector [(ngModel)]="teamAccessArray"
                                    [options]="teams"
                                    displayField="name"
                                    valueField="id"
                                    placeholder="Other Teams This User has Access To"
                                    name="teamAccessSelector"
                                    
                                >
                            </multi-selector>
                        </mat-form-field>
                    </div>
                </div>

                <div data-layout="row" class="meta-actions">
                    <div data-flex *ngIf="editableUser.id && (editableUser !== currentUser)">
                        <button mat-raised-button type="button" (click)="sendPasswordReset()">
                            Send Password Reset
                        </button>
                    </div>
                </div>

                <div class="section-action-bottom-buttons">
                    <span>
                        <mat-slide-toggle name="active" [(ngModel)]="editableUser.active" labelPosition="before"
                                *ngIf="editableUser.id && (editableUser !== currentUser) && currentUser.hasPermission('user-delete')">
                            Active
                        </mat-slide-toggle>
                    </span>

                    <button mat-raised-button type="button" (click)="closeUserEdit($event, true)">
                        Cancel
                    </button>

                    <button mat-raised-button type="submit" *ngIf="!editableUser.id" [disabled]="!userUpdateForm.form.valid">
                        Invite
                    </button>

                    <button mat-raised-button type="submit" *ngIf="editableUser.id" [disabled]="!userUpdateForm.form.valid">
                        Update
                    </button>
                </div>
            </form>
        </div>
    </section>
</div>
