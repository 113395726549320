<div class="deal-search-details-view" data-layout="row" data-layout-gap="40px" *ngIf="localizedDeal">
    <div class="flex-200">
        <div class="image-container product">
            <img [src]="productImageUrl" [alt]="localizedDeal.name" />
        </div>

        <div class="detail-wrapper">{{ localizedDeal.customPrice }}</div>

        <div class="image-container chain">
            <img [src]="chainImageUrl" [alt]="localizedDeal.chain.name + ' logo'" />
        </div>

        <div class="detail-wrapper">{{ localizedDeal.chain?.name }}</div>
    </div>

    <div data-flex>
        <div class="deal-status" [class.active]="isActive">{{ dealStatus }}</div>

        <div data-layout="row" data-layout-gap="1rem">
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal.upc" name="upc" placeholder="UPC" disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <input matInput
                            [ngModel]="promotionDates"
                            name="dates"
                            placeholder="Promotion Dates"
                            disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <mat-select [(ngModel)]="displayLanguage" (ngModelChange)="setDisplayModel()"
                            [disabled]="!enableLanguageSelector"
                            name="language"
                            placeholder="Deal Display Language">
                        <mat-option *ngFor="let language of availableLanguages" [value]="language.value">
                            {{ language.key }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div data-layout="row" data-layout-gap="1rem">
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal?.brand" name="brand" placeholder="Brand" disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal?.name" name="name" placeholder="Name" disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal?.description" name="description" placeholder="Description" disabled />
                </mat-form-field>
            </div>
        </div>

        <div data-layout="row" data-layout-gap="1rem">
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal?.manufacturer" name="manufacturer" placeholder="Manufacturer" disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="uom" name="uom" placeholder="UOM" disabled />
                </mat-form-field>
            </div>
            <div data-flex="33">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal?.container" name="container" placeholder="Container" disabled />
                </mat-form-field>
            </div>
        </div>

        <div data-layout="row">
            <div data-flex="100">
                <mat-form-field>
                    <input matInput [ngModel]="localizedDeal.entryFlyerName || '--'" name="flyer" placeholder="Flyer Name" disabled />
                </mat-form-field>
            </div>
        </div>

        <div data-layout="row">
            <div data-flex="100">
                <mat-form-field>
                    <textarea matInput matTextareaAutosize cdkAutosizeMinRows="1"
                            [ngModel]="localizedDeal?.additional"
                            name="additional"
                            placeholder="Additional Information"
                            disabled >
                    </textarea>
                </mat-form-field>
            </div>
        </div>

        <div data-layout="row">
            <div data-flex="100">
                <mat-form-field>
                    <textarea matInput matTextareaAutosize cdkAutosizeMinRows="1"
                            [ngModel]="marketAreaText"
                            name="marketAreas"
                            placeholder="Market Areas"
                            disabled >
                    </textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div class="section-action-bottom-buttons">
    <button mat-raised-button (click)="back()">Back</button>
</div>
