/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Inject,
    Injectable
} from "@angular/core";

import {
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class SmartRedirectGuard implements CanActivate {

    private readonly _smartAppUrl: string;

    constructor(
        private readonly _router: Router,
        @Inject("AppConfig") private readonly _appConfig: any
    ) {
        this._smartAppUrl = this._appConfig.smartAppUrl;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        window.location.href = this._smartAppUrl;
        return true;
    }
}
