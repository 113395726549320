<div class="page-content">
    <action-button-group [actions]="actions" (onAction)="actionTriggered($event)"></action-button-group>

    <div class="section-filter">
        <mat-form-field class="full-width">
            <input matInput [(ngModel)]="query" (keyup.enter)="updateQuery()"
                    (input)="onSearchChange()" placeholder="Filter by keyword" autofocus="true" />

            <button mat-icon-button matSuffix (click)="clearQuery()" *ngIf="query">
                <mat-icon>
                    clear
                </mat-icon>
            </button>
        </mat-form-field>
    </div>

    <section class="page-content-section with-filter">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="keyword">
                <mat-header-cell *matHeaderCellDef> Keyword </mat-header-cell>
                <mat-cell *matCellDef="let keyword">
                    {{ keyword.keyword }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="categories">
                <mat-header-cell *matHeaderCellDef> Categories </mat-header-cell>
                <mat-cell *matCellDef="let keyword">
                    <span *ngFor="let category of keyword.majorCategoryIds | categoryName:categories" class="single-keyword-category">
                        {{ category }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="query">
                <mat-header-cell *matHeaderCellDef> Query </mat-header-cell>
                <mat-cell *matCellDef="let keyword">
                    {{ keyword.searchQuery }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> <!-- empty --> </mat-header-cell>
                <mat-cell *matCellDef="let keyword">
                    <button mat-icon-button matTooltip="Delete" matTooltipPosition="above" (click)="deleteKeyword(keyword, $event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let keyword; columns: displayedColumns;" (click)="setSelectedKeyword(keyword)"
                class="clickable">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 20, 50, 100]">
        </mat-paginator>
    </section>
</div>
