<div class="page-content">
    <section class="page-content-section">
        <form #keywordForm="ngForm" (submit)="submitKeyword($event)">
            <div class="keyword-form-wrapper">
                <div data-layout="row">
                    <div data-flex="100">
                        <mat-form-field class="full-width">
                            <input matInput [(ngModel)]="selectedKeyword.keyword" name="keyword" placeholder="Keyword" required />
                        </mat-form-field>
                    </div>
                </div>
    
                <div data-layout="row">
                    <div data-flex="100">
                        <mat-form-field class="full-width">
                            <input matInput [(ngModel)]="selectedKeyword.searchQuery" name="searchQuery" placeholder="Search Query" required />
                        </mat-form-field>
                    </div>
                </div>
    
                <div data-layout="row">
                    <div data-flex="100">
                        <mat-form-field *ngIf="categories">
                            <multi-selector [(ngModel)]="selectedKeyword.majorCategoryIds"
                                    [options]="categories"
                                    displayField="name"
                                    valueField="id"
                                    placeholder="Categories"
                                    name="categorySelector"
                                >
                            </multi-selector>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            
            <div class="section-action-bottom-buttons">
                <button type="button" mat-raised-button (click)="cancel($event)">
                    Cancel
                </button>
                
                <button type="submit" mat-raised-button [disabled]="!keywordForm.form.valid">
                    Save
                </button>
            </div>
        </form>
    </section>
</div>
