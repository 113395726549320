import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import {
    BooleanModule,
    BreadcrumbsModule,
    ValidatorModule,
    FormsModule,
    TableModule,
    LanguageModule,
    UserNameModule
} from "@shopliftr/common-ng";
import {
    KeyValueModule, CommonDateModule
} from "@shopliftr/common-ng";

import { HotkeysModule } from "@shopliftr/hotkeys";
import { MgdHotkeyModule } from "@shopliftr/common-ng";
import { CapitalizePipe } from "../pipes/capitalize.pipe";
import { CategoryNamePipe } from "../pipes/category-name.pipe";
import { DisplayAddressPipe } from "../pipes/display-address.pipe";
import { FilterDateDropdownPipe } from "../pipes/filter-date-dropdown.pipe";
import { PagerPipe } from "../pipes/pager.pipe";
import { PriorityPipe } from "../pipes/priority.pipe";
import { SecondsToTimePipe } from "../pipes/seconds-time.pipe";
import { SortAlphabeticallyPipe } from "../pipes/sort-alphabetically.pipe";
import { TeamNamePipe } from "../pipes/team-name.pipe";

import { DollarFormatValidatorDirective } from "./validators/dollar-format-validator.directive";
import { DurationValidatorDirective } from "./validators/duration-validator.directive";
import { NonEmptyValidatorDirective } from "./validators/non-empty-validator.directive";
import { PercentFormatValidatorDirective } from "./validators/percent-format-validator.directive";
import { PositiveValueValidatorDirective } from "./validators/positive-value-validator.directive";
import { SalePercentLimitValidatorDirective } from "./validators/sale-percent-limit-validator.directive";
import { SalePriceValidatorDirective } from "./validators/sale-price-warning-validator.directive";
import { UpcValidatorDirective } from "./validators/upc-validator.directive";
import { WholeNumberValidatorDirective } from "./validators/whole-number-validator.directive";


@NgModule({
    declarations: [
        CapitalizePipe,
        CategoryNamePipe,
        DisplayAddressPipe,
        DollarFormatValidatorDirective,
        DurationValidatorDirective,
        FilterDateDropdownPipe,
        NonEmptyValidatorDirective,
        PagerPipe,
        PercentFormatValidatorDirective,
        PositiveValueValidatorDirective,
        PriorityPipe,
        SalePercentLimitValidatorDirective,
        SalePriceValidatorDirective,
        SecondsToTimePipe,
        SortAlphabeticallyPipe,
        TeamNamePipe,
        UpcValidatorDirective,
        WholeNumberValidatorDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        KeyValueModule,
        CommonDateModule,
        LanguageModule,
        RouterModule,
        ValidatorModule,
        BooleanModule,
        UserNameModule,
        BreadcrumbsModule,
        FormsModule,
        TableModule,
        HotkeysModule,
        MgdHotkeyModule
    ],
    exports: [
        CapitalizePipe,
        CategoryNamePipe,
        DisplayAddressPipe,
        FilterDateDropdownPipe,
        PagerPipe,
        PriorityPipe,
        SecondsToTimePipe,
        CommonModule,
        FormsModule,
        TeamNamePipe,
        SortAlphabeticallyPipe,
        KeyValueModule,
        DollarFormatValidatorDirective,
        DurationValidatorDirective,
        NonEmptyValidatorDirective,
        PercentFormatValidatorDirective,
        PositiveValueValidatorDirective,
        SalePercentLimitValidatorDirective,
        SalePriceValidatorDirective,
        UpcValidatorDirective,
        WholeNumberValidatorDirective,
        CommonDateModule,
        BooleanModule,
        BreadcrumbsModule,
        FormsModule,
        TableModule,
        HotkeysModule,
        MgdHotkeyModule,
        LanguageModule,
        UserNameModule
    ],
    providers: [
        DisplayAddressPipe,
        FilterDateDropdownPipe,
        PriorityPipe,
        SortAlphabeticallyPipe
    ]
})
export class SharedModule {}
