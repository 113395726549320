<mat-sidenav-container class="admin-component">
    <mat-sidenav mode="side" [opened]="sideNavOpened" class="admin-main-navigation alt-theme" *ngIf="currentUser">
        <div class="user-information-wrapper">
            <div class="logo-wrapper">
                <img class="partner-logo-image" src="../../../assets/images/logo-white-shopliftr.svg" />
            </div>

            <div class="user-name text-bold">
                 {{ currentUser.name | capitalizePipe }}
            </div>

            <div>
                <button type="button" mat-icon-button [matMenuTriggerFor]="profileActions">
                    <mat-icon>
                        settings
                    </mat-icon>
                </button>
            </div>

            <mat-menu #profileActions="matMenu">
                <button mat-menu-item (click)="goToMyProfile()"
                        *ngIf="currentUser.permissions.includes('user-self-update') || currentUser.permissions.includes('user-update')">
                    My Profile
                </button>
                <button mat-menu-item (click)="goToUpdatePassword()"
                        *ngIf="currentUser.permissions.includes('user-self-update') || currentUser.permissions.includes('user-update')">
                    Update Password
                </button>
                <button mat-menu-item (click)="showDataExportDialog()" *ngIf="currentUser.permissions.includes('campaign-read')">
                    Grocery Outlet Data Export
                </button>
                <button mat-menu-item (click)="showStatusReportDialog()" *ngIf="currentUser.permissions.includes('campaign-read')">
                    Grocery Outlet Status Report
                </button>
                <button *ngIf="displayThemeToggle"  mat-menu-item (click)="toggleTheme()">
                    Toggle Partner Theme
                </button>
                <button mat-menu-item (click)="logout()">
                    Logout
                </button>
            </mat-menu>
        </div>

        <hr />

        <mat-nav-list>
            <mat-list-item class="admin-nav-item"
                    matTooltip="Dashboard" matTooltipPosition="after" routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./dashboard']" [queryParams]="{}">
                    <mat-icon mat-list-icon>home</mat-icon>
                    <span>Dashboard</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('task-read')" matTooltip="Tasks"
                    matTooltipPosition="after" routerLinkActive="current-route">
                <a matLine [routerLink]="['./tasks']" [queryParams]="{}">
                    <mat-icon mat-list-icon>assignment</mat-icon>
                    <span>Tasks</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" matTooltip="User List" matTooltipPosition="after"
                    routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./users']" [queryParams]="{}">
                    <mat-icon mat-list-icon>people</mat-icon>
                    <span>Users</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('product-update')" matTooltip="Product Management"
                    matTooltipPosition="after" routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./product/management']" [queryParams]="{}">
                    <mat-icon mat-list-icon>shopping_cart</mat-icon>
                    <span>Products</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('deal-search')" matTooltip="Deal Search"
                    matTooltipPosition="after" routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./deal-search']" [queryParams]="{}">
                    <mat-icon mat-list-icon>local_offer</mat-icon>
                    <span>Deal Search</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('api-key-read')" matTooltip="Api Keys" matTooltipPosition="after"
                    routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./api-keys']" [queryParams]="{}">
                    <mat-icon mat-list-icon>vpn_key</mat-icon>
                    <span>Api Keys</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('keyword-read')" matTooltip="Keywords" matTooltipPosition="after"
                    routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./keywords']" [queryParams]="{}">
                    <mat-icon mat-list-icon>youtube_searched_for</mat-icon>
                    <span>Keywords</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('campaign-read')" matTooltip="SMART Management" matTooltipPosition="after"
                    routerLinkActive="current-route">
                <a matLine [routerLink]="['./smart']" [queryParams]="{}" target="_blank" rel="noreferrer noopener">
                    <mat-icon mat-list-icon>place</mat-icon>
                    <span>SMART</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('flyerVersion-read')" matTooltip="Store Management" matTooltipPosition="after"
                    routerLinkActive="current-route">
                <a matLine [routerLink]="['./flyer-version']" [queryParams]="{}">
                    <mat-icon mat-list-icon>store</mat-icon>
                    <span>Store Management</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('campaign-read')" matTooltip="Reporting" matTooltipPosition="after"
                    routerLinkActive="current-route">
                <a matLine [routerLink]="['./reporting']" [queryParams]="{}" target="_blank" rel="noreferrer noopener">
                    <mat-icon mat-list-icon>grid_on</mat-icon>
                    <span>Reporting</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('tools-use')" matTooltip="Tools and Utilities" matTooltipPosition="after"
                    routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./tools']" [queryParams]="{}">
                    <mat-icon mat-list-icon>build</mat-icon>
                    <span>Tools</span>
                </a>
            </mat-list-item>

            <hr />

            <mat-list-item class="admin-nav-item" *ngIf="currentUser.hasPermission('deal-create')" matTooltip="Getting Started" matTooltipPosition="after"
                    routerLinkActive="current-route" [routerLinkActiveOptions]="{exact: true}">
                <a matLine [routerLink]="['./getting-started']" [queryParams]="{}">
                    <mat-icon mat-list-icon>info</mat-icon>
                    <span>Get Started</span>
                </a>
            </mat-list-item>

            <mat-list-item class="admin-nav-item" matTooltip="Report a Problem" matTooltipPosition="after">
                <a matLine [routerLink]="[]" (click)="onReportProblem()">
                    <mat-icon mat-list-icon>contact_support</mat-icon>
                    <span>Report a Problem</span>
                </a>
            </mat-list-item>
        </mat-nav-list>
        <div data-layout="row" data-layout-align="center end" class="version">
            <p>
                version: {{ build }}
            </p>
        </div>
    </mat-sidenav>
    <div class="main-content-wrapper" id="content">
        <div class="main-content-container">
            <router-outlet></router-outlet>
        </div>
    </div>
</mat-sidenav-container>
