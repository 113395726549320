<div matDialogTitle>
    <h2 *ngIf="usersToUpdate.length === 1">
        Deactivate this user?
    </h2>
    <h2 *ngIf="usersToUpdate.length > 1">
        Deactivate these {{usersToUpdate.length}} users?
    </h2>

    <button mat-icon-button matDialogClose class="mat-dialog-close">
        <mat-icon>
            clear
        </mat-icon>
    </button>
</div>

<mat-dialog-content>
    <div *ngIf="usersToUpdate.length > 1" (click)="toggleUserView()" class="lazy-load-button">
        {{(showLimited ? "View " : "Hide ") + usersToUpdate.length}} users to be deactivated.
    </div>
    <div class="selected-users" *ngIf="!showLimited">
        <div class="selected-users-header">
            <div data-layout="row">
                <div data-flex="42">
                    Email
                </div>
                <div data-flex="33">
                    Name
                </div>
                <div data-flex="25">
                    Roles
                </div>
            </div>
        </div>
        <div *ngFor="let selectedUser of usersToUpdate" class="single-selected-user">
            <div data-layout="row">
                <div data-flex="42">
                    {{selectedUser.email}}
                </div>
                <div data-flex="33">
                    {{selectedUser.name }}
                </div>
                <div data-flex="25">
                    <span *ngFor="let role of selectedUser.roles">
                        {{role | capitalizePipe}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="actions">
        <button mat-raised-button matDialogClose>
            Cancel
        </button>

        <button mat-raised-button (click)="deactivateSelectedUsers()">
            <span *ngIf="usersToUpdate.length === 1">
                Deactivate user
            </span>
            <span *ngIf="usersToUpdate.length > 1">
                Deactivate ({{usersToUpdate.length}}) users
            </span>
        </button>
    </div>
</mat-dialog-actions>
