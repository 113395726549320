<div matDialogTitle>
    <h2>{{ dialogTitle }}</h2>

    <button mat-icon-button matDialogClose class="mat-dialog-close">
        <mat-icon>
            clear
        </mat-icon>
    </button>
</div>

<mat-dialog-content class="batch-id-dialog-component">
    <form [formGroup]="batchIdForm" (mgdSubmitForm)="submit()">
        <mat-form-field>
            <input mgdClearInput matInput type="text" name="batchId" formControlName="batchId" placeholder="Batch ID"/>
            
            <button tabindex="-1" mat-icon-button matSuffix matTooltip="Clear (Ctrl + Backspace)" type="button" (click)="batchIdControl.reset()" *ngIf="batchIdControl.value">
                <mat-icon>
                clear
                </mat-icon>
            </button>
        </mat-form-field>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="confirmation-actions" data-layout="row" data-layout-align="center center">
    <div class="actions">
        <button mat-raised-button matDialogClose type="reset">
            Cancel
        </button>
        
        <button mat-raised-button type="submit" (click)="submit()" [disabled]="!canSubmit" class="main-action-button">
            Perform Task <span class="hot-key-label transparent">(Ctrl + Enter)</span>
        </button>
    </div>
</mat-dialog-actions>
  