<div class="page-header">
    <header class="page-title">
        <h2>Get Started</h2>
    </header>
</div>
<div class="page-content">
    <section class="page-content-section">
        <getting-started-markdown></getting-started-markdown>
    </section>
</div>
