<section class="deal-search-list page-content-section with-filter">
    <mat-table #table matSort class="deal-entry-list"
            [dataSource]="dataSource"
            [hidden]="processing"
            (cdkObserveContent)="contentChanged()"
            [debounce]="250"
            (matSortChange)="onSortChange($event)"
    >
        <ng-container cdkColumnDef="brand">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Brand
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <div>
                    {{ row.brand || "--" }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="description">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Description
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <div>
                    {{ row.description || "--" }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="entryFlyerName">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Flyer
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                {{ row.entryFlyerName || "--" }}
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="image">
            <mat-header-cell *cdkHeaderCellDef>
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <img class="product-image" src="{{row.imageUrl}}" />
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="name">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Name
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <div>
                    {{ row.name || "--" }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="price">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Price
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                {{ row.customPrice }}
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="saleEnd">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Sale End
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                {{ row.saleEnd | commonDate : dateDisplayFormat : timezone }}
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="saleStart">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                Sale Start
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                {{ row.saleStart | commonDate : dateDisplayFormat : timezone }}
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="uom">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                UOM
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                {{ row.size }} {{ row.uom }}
            </mat-cell>
        </ng-container>

        <ng-container cdkColumnDef="upc">
            <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                UPC
            </mat-header-cell>
            <mat-cell *cdkCellDef="let row">
                <div>
                    {{ row.upc || "--" }}
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *cdkHeaderRowDef="displayColumns"></mat-header-row>
        <mat-row *cdkRowDef="let deal; columns: displayColumns;"
                [class.clickable]="!disabled"
                [class.highlighted]="isHighlighted(deal.id)"
                (click)="onSelect($event, deal)"
        ></mat-row>
    </mat-table>

    <mat-paginator #paginator
            *ngIf="total && !processing"
            [length]="total || 0"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)">
    </mat-paginator>

    <div data-layout="row" data-layout-align="center center" class="empty-state-container" *ngIf="displayEmptyState">
        <div data-flex="100">
            <img src="/assets/images/empty-state-review.svg" />
            <p class="empty-state-warning">No deals match your search criteria</p>
        </div>
    </div>

    <div data-layout="row" data-layout-align="center center" class="empty-state-container" *ngIf="processing">
        <div data-flex="100">
            <p class="empty-state-warning">Loading...</p>
        </div>
    </div>
</section>
