<header class="page-title">
    <h2>Dashboard</h2>

    <mat-button-toggle-group [(ngModel)]="currentDashboard" class="dashboard-selector"
            [class.dashboard-filter-bar]="(currentDashboard === 'team') && (currentUser.teamAccess && currentUser.teamAccess.length > 1)"
            *ngIf="dashboardCount > 1">
        <mat-button-toggle value="team">
            Team
        </mat-button-toggle>
        <mat-button-toggle value="user">
            Mine
        </mat-button-toggle>
    </mat-button-toggle-group>
    
    <mat-form-field class="team-selector" *ngIf="currentDashboard">
        <team-selector [realms]="['deal-entry']" [(ngModel)]="team"></team-selector>
    </mat-form-field>
</header>

<team-dashboard *ngIf="currentDashboard === 'team' && team" [team]="team" (onTaskNavigate)="onTaskNavigate($event)"></team-dashboard>

<user-dashboard *ngIf="currentDashboard === 'user'" (onTaskNavigate)="onTaskNavigate($event)"></user-dashboard>

<div class="no-dashboard" *ngIf="!currentDashboard">
    <h1>Shopliftr Admin</h1>
    <p>Welcome to the Shopliftr admin portal. Select an option from the menu on the left.</p>
</div>
