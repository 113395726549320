<nav mat-tab-nav-bar class="secondary-nav-wrapper">
    <a mat-tab-link [active]="timePeriodIsToday" (click)="setDateFilter(filterTimePeriod.Today)">
        Today
    </a>
    <a mat-tab-link [active]="timePeriodIsWeek" (click)="setDateFilter(filterTimePeriod.Week)">
        This Week
    </a>
    <a mat-tab-link [active]="timePeriodIsMonth" (click)="setDateFilter(filterTimePeriod.Month)">
        This Month
    </a>
</nav>

<div class="page-content">
    <action-button-group [actions]="actions" (onAction)="actionTriggered($event)"></action-button-group>

    <div class="section-filter">
        <div data-layout="row" data-layout-gap="1rem">
            <div data-flex>
                <mat-form-field>
                    <mat-label>Choose a date range</mat-label>
                    <mat-date-range-input
                        [rangePicker]="rangePicker"
                        mgdDateRangePicker
                    >
                        <input matStartDate placeholder="Start date" [(ngModel)]="startDate" name="start" #start="ngModel" readonly>
                        <input matEndDate placeholder="End date" [(ngModel)]="endDate" name="end" #end="ngModel" readonly required>
                    </mat-date-range-input>
                    <mat-hint>YYYY-MM-DD - YYYY-MM-DD</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #rangePicker disabled="false"></mat-date-range-picker>

                    <mat-error *ngIf="!start.control.getError('required') && start.errors">Invalid start date</mat-error>
                    <mat-error *ngIf="!end.control.getError('required') && end.errors">Invalid end date</mat-error>
                    <mat-error *ngIf="end.control.getError('required')">End date required</mat-error>
                    <mat-error *ngIf="start.control.getError('required')">Start date required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <section class="page-content-section with-filter">
        <div class="team-dashboard-component">
            <div class="dashboard-unbounded-container">
                <div data-layout="row">
                    <div data-flex="50" class="view-stat">
                        <a role="button" (click)="goToTasks('Team')">
                            <div class="view-stat-block current-view">
                                <div class="stat-block-figure">
                                    {{ teamProgress.currentlyIncomplete }}
                                </div>
                                <div class="stat-block-label">
                                    Currently Incomplete
                                </div>
                            </div>
                        </a>
                    </div>
                    <div data-flex="50">
                        <a role="button" (click)="goToTasks('Team Late')">
                            <div class="view-stat border-left">
                                <div class="view-stat-block current-view">
                                    <div class="stat-block-figure">
                                        {{ teamProgress.currentlyLate }}
                                    </div>
                                    <div class="stat-block-label">
                                        Currently Late
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div class="dashboard-charts-container">
                <div *ngIf="!chartsLoading">
                    <task-chart [taskCounts]="taskCounts" [teamProgress]="teamProgress"></task-chart>
                </div>
                <div data-layout="row" *ngIf="chartsLoading" class="preloader-container">
                    <div data-flex="100">
                        <div class="loader">Loading...</div>
                    </div>
                </div>
            </div>

            <div class="dashboard-user-progress">
                <div data-layout="row" class="headings">
                    <div class="flex-23-5">
                        Team Member
                    </div>
                    <div class="text-center flex-8-5">
                        Deals Entered
                    </div>
                    <div class="text-center flex-8-5">
                        Avg Entry Time
                    </div>
                    <div class="text-center flex-8-5">
                        Incomplete
                    </div>
                    <div class="text-center flex-8-5">
                        Completed
                    </div>
                    <div class="text-center flex-8-5">
                        Late
                    </div>
                    <div class="text-center flex-8-5">
                        Rejected**
                    </div>
                    <div class="text-center flex-8-5">
                        Cancelled
                    </div>
                    <div class="text-center current-view">
                        Currently Incomplete
                    </div>
                    <div class="text-center current-view">
                        Currently Late
                    </div>
                </div>
                <div *ngIf="!searching && !chartsLoading">
                    <div data-layout="row" class="values" *ngFor="let progress of userProgress">
                        <div class="team-member flex-23-5">
                            {{ progress.userName }}
                            ({{ progress.roles }})
                            <span *ngIf="progress.qa">|
                                <em>
                                    QA
                                </em>
                            </span>
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.dealsEntered }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.avgEntryTime | SecondsToTimePipe }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.unfinishedTasks }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.finishedTasks }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.lateTasks }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.rejectedTasks }}
                        </div>
                        <div class="text-center flex-8-5">
                            {{ progress.cancelledTasks }}
                        </div>
                        <div class="text-center current-view flex-8-5">
                            {{ progress.currentlyIncomplete }}
                        </div>
                        <div class="text-center current-view flex-8-5">
                            {{ progress.currentlyLate }}
                        </div>
                    </div>
                    <p class="footer-text">
                        ** Number of times a flyer was rejected.  Not the current number of rejected flyers.
                    </p>
                </div>
            </div>

            <div data-layout="row" data-layout-align="center center" class="empty-state-container"
                    *ngIf="!searching && (!userProgress || userProgress.length === 0)">
                <div data-flex="100">
                    <img src="../../../assets/images/empty-state-tasks.svg" />
                    <p class="empty-state-warning">
                        No tasks available for selected filter
                    </p>
                </div>
            </div>
            <div data-layout="row" *ngIf="searching || chartsLoading" class="preloader-container">
                <div data-flex="100">
                    <div class="loader">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
